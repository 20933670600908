import {
  validateForm,
  maskNumber,
  maskSimplePhone,
  maskPhone,
  maskInternationalPhone,
  initPasswordEye,
  initAgreeCheckbox,
  initFileLoadInput,
  focusFirstInput,
  initSelectValidation,
  initChoicesValidation,
} from "../validator/validator";
import { summonAlert, removeAlert } from "../alert/alert";
import { summonPopUp, removePopUp } from "../popUp/popUp";
import { addLoading, removeLoading } from "../loading/loading";
import {
  getPaddingOnBody,
  getPaddingFromBody,
  getScrollbarWidth,
  scrollToErrorField,
  setStatus,
  setTextareaAutoHeight,
  activateRequestButtons,
  debounce,
} from "../../utils/utils";
import {
  setCellHeight,
  tarifsSelectInit,
} from '../tarifs/tarifs';
import activateModalTarif from '../modal-tarif/modal-tarif';

window.Corners5ProjectLayout = {
  validation: {
    validateForm,
    maskSimplePhone,
    maskNumber,
    maskPhone,
    maskInternationalPhone,
    initPasswordEye,
    initAgreeCheckbox,
    initFileLoadInput,
    focusFirstInput,
    initSelectValidation,
    initChoicesValidation,
  },
  summonAlert,
  removeAlert,
  summonPopUp,
  removePopUp,
  addLoading,
  removeLoading,
  getPaddingOnBody,
  getPaddingFromBody,
  getScrollbarWidth,
  scrollToErrorField,
  setStatus,
  setTextareaAutoHeight,
  activateRequestButtons,
  debounce,
  setCellHeight,
  tarifsSelectInit,
  activateModalTarif,
};
