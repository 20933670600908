import Swiper from 'swiper';
import {
  Navigation,
  Pagination,
  Scrollbar,
} from 'swiper/modules';
import Choices from 'choices.js';

const slider = document.querySelector('.tarifs__swiper');
if (slider) {
  const sliderNolint = new Swiper(slider, {
    modules: [Navigation, Pagination, Scrollbar],
    // Optional parameters
    slidesPerView: 'auto',
    spaceBetween: 0,
    loop: false,
    // Scrollbar
    scrollbar: {
      el: '.slider__scrollbar',
      dragClass: 'slider__scrollbar-drag',
      draggable: true,
    },
    // Responsive breakpoints
    breakpoints: {},
  });
}

const setCellHeight = () => {
  const table = document.querySelector('.tarifs__table');
  if (table) {
    const maxSizes = [];
    const cols = table.querySelectorAll('.tarifs__col');
    cols.forEach((col, index) => {
      const cells = col.querySelectorAll('.tarifs__th, .tarifs__td');
      cells.forEach((cell, kindex) => {
        if (index === 0) {
          maxSizes.push(cell.scrollHeight);
        } else {
          if (maxSizes[kindex] < cell.scrollHeight) {
            maxSizes[kindex] = cell.scrollHeight;
          }
        }
      });
    });

    cols.forEach((col) => {
      const cells = col.querySelectorAll('.tarifs__th, .tarifs__td');
      cells.forEach((cell, kindex) => {
        cell.style.height = `${maxSizes[kindex]}px`;
      });
    });

  } else {
    return null;
  }
};

window.addEventListener('load', () => {
  setCellHeight();
});

// document.addEventListener('DOMContentLoaded', () => {
//   setCellHeight();
// });

const tarifsSelectInit = (func) => {
  const tarifsSelect = document.querySelector('.tarifs__select');
  if (tarifsSelect) {
    let choicesNolint;
    const select = tarifsSelect;
    const list = [];

    select.querySelectorAll('option').forEach((item) => {
      const name = item.textContent;
      const { discount } = item.dataset;
      const address = {
        value: name,
        label: name,
        customProperties: {
          discount,
        },
      };

      list.push(address);
      item.remove();
    });

    choicesNolint = new Choices(select, {
      searchEnabled: false,
      itemSelectText: '',
      shouldSort: false,
      choices: list,
      classNames: {
        containerOuter: 'choices tarifs__choices',
      },

      callbackOnCreateTemplates(template) {
        return {
          // choice: (classNames, data) => template(
          //   `<div class="${classNames.item} ${classNames.itemChoice} ${data.selected ? 'is-selected' : ''} ${data.disabled
          //     ? classNames.itemDisabled
          //     : classNames.itemSelectable}" data-select-text="${this.config.itemSelectText}" data-choice ${data.disabled
          //       ? 'data-choice-disabled aria-disabled="true"'
          //       : 'data-choice-selectable'} data-id="${data.id}" data-value="${data.value}" ${data.groupId > 0 ? 'role="treeitem"' : 'role="option"'}>
          //     ${data.label} <em>Скидка ${data.customProperties.discount}%</em>
          //   </div>`,
          // ),
          item: ({ classNames }, data) => {
            return template(`
              <div class="${classNames.item} ${data.highlighted
                ? classNames.highlightedState
                : classNames.itemSelectable
                } ${data.placeholder ? classNames.placeholder : ''
                }" data-item data-id="${data.id}" data-value="${data.value}" ${data.active ? 'aria-selected="true"' : ''
                } ${data.disabled ? 'aria-disabled="true"' : ''}>
                ${data.label} <em>скидка ${data.customProperties.discount}%</em>
              </div>
            `);
          },
          choice: ({ classNames }, data) => {
            return template(`
              <div class="${classNames.item} ${classNames.itemChoice} ${data.selected ? 'is-selected' : ''} ${data.disabled ? classNames.itemDisabled : classNames.itemSelectable
                }" data-select-text="${this.config.itemSelectText}" data-choice ${data.disabled
                ? 'data-choice-disabled aria-disabled="true"'
                : 'data-choice-selectable'
                } data-id="${data.id}" data-value="${data.value}" ${data.groupId > 0 ? 'role="treeitem"' : 'role="option"'
                }>
                ${data.label} <em>Скидка ${data.customProperties.discount}%</em>
              </div>
            `);
          },
        };
      },
    });

    // Убирает подсвеченный пункты при закрытии выпадающего списка.
    select.addEventListener('hideDropdown', () => {
      select.querySelectorAll('.is-highlighted').forEach((el) => {
        el.classList.remove('is-highlighted');
      });
    });

    select.addEventListener('addItem', (event) => {
      func(event);
    });
    return choicesNolint;
  }
};

export {
  setCellHeight,
  tarifsSelectInit,
};
