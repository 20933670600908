import {
  summonPopUp,
  removePopUp,
} from '../popUp/popUp';
import {
  summonAlert,
  removeAlert,
} from "../alert/alert";
import {
  debounce,
} from '../../utils/utils';
import {
  addLoading,
  removeLoading,
} from '../loading/loading';
import {
  validateForm,
} from '../validator/validator';

const activateModalTarif = ({
  fetchInfo,
  postInfo,
}) => {
  const requests = document.querySelectorAll('.request-modal-tarif:not(.request-modal-tarif--js)');
  if (requests.length) {
    requests.forEach((request) => {
      request.classList.add('request-modal-tarif--js');
      const button = request;
      const { type } = button.dataset;

      const getModalForm = () => {
        const info = button.dataset;
        const modal = document.querySelector(`.modal--${type}`);
        const form = modal.querySelector('form');

        for(let key in info) {
          form.insertAdjacentHTML('beforeend', `<input type="hidden" name=${key} value=${info[key]}>`);
        }

        const validatedForm = validateForm(`.modal--${type} form`);

        const post = async () => {
          addLoading();
          const answer = await postInfo(form);
          if (answer) {
            if (answer.status === 'success') {
              validatedForm.destroy();
              removePopUp(`.modal--${type}`, true);
              removeLoading();
            }
          }
        };

        form.addEventListener('bouncerFormValid', debounce(() => {
          if (postInfo) {
            post();
          }
        }));
      };

      const fetch = async () => {
        addLoading();
        const info = button.dataset;
        const data = new FormData();

        for(let key in info) {
          data.append(key, info[key]);
        }

        const answer = await fetchInfo(data);

        if (answer) {
          const container = document.querySelector(`#modal--${type}`).content.querySelector('.modal-tarif__wrapper');
          container.innerHTML = answer;
          summonPopUp(`.modal--${type}`, true);
          removeLoading();

          getModalForm();
        } else {
          summonAlert("#alert--error");
          removeLoading();
        }

        // if (answer) {
        //   if (answer.status === 'success') {
        //     summonPopUp(`.modal--${type}`, true);
        //     removeLoading();

        //     getModalForm();
        //   }
        // }
      };

      button.addEventListener('click', debounce(() => {
        if (fetchInfo && type) {
          fetch();
        }
      }));
    });
  }
};

export default activateModalTarif;
